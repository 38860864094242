const config = {
  name: 'MobiAzores',
  operator: 'mobiazores',
  operatorID: 'MOBIAZORES',
  agencyID: 250,
  info: {
    email: 'info@mobiazores.pt',
    phone: '+351 295 217 001',
    support_email: 'info@mobiazores.pt',
    support_phone: '+351 295 217 001',
    recruitment_email: 'info@mobiazores.pt',
    copyright: '©',
  },
  sections: {
    about: true, // About page
    ads: {
      mastercard: true,
      pass_migration: false,
      pick: false,
      trevo_fare_update: false,
      proximo_satisfaction_survey: false,
      trevo_route_change: false,
      trevo_fair_schedules: false,
    },
    cookie_policy: true, // Cookies policy and cookie permission dialog
    faqs: true, // FAQs page
    funding: false, // Additional info below footer
    migration: false, // Pass migration page
    news: false, // News page
    pass_requests: false, // Enables requesting travel cards
    pass_renewal: false, // Enables renewing travel cards
    pass_requirements: 7, // Pass Requirements
    passenger_rights: false, // Passenger's rights page
    pass_request_policy: false, // Pass Request Data Protection Policy
    pass_migration: true, // Additional info before ticket rates
    pick: false, // Toggles all components related to the PICK app
    privacy_policy: true, // Privacy Policy page
    promotion: false, // Promotion page
    recruitment: true, // Recruitment page
    reviews: false, // Passenger reviews on the home page
    schedules: { modal: true, download: false }, // Schedules modal
    support: true, // Customer Support page
    terms_conditions: true, // Terms and Conditions page
    ticket_agents: 2, // Ticket Agents component
    ticket_info: false, // Additional info before ticket rates
    ticket_rates: false, // Ticket Prices
    zone_map: false, // Zone Map for Routes to display in Tickets & Fares and Network & Schedules
  },
  mainTitleColor: { sm: '#475467', lg: '#475467' },
  mainColor: '#0054B6', //primary600
  hoverColor: '#F8FBFF', //primary25
  // Progress Style is always primary500 in RGBA
  progressStyle:
    'linear-gradient(to right, rgba(0, 105, 228, 0), rgba(0, 105, 228, 1), rgba(0, 105, 228, 1), rgba(0, 105, 228, 0))',
  themeTokens: {
    colors: {
      primaryA20: 'rgba(19,  128, 255, 0.20)',
      primaryA40: 'rgba(19,  128, 255, 0.40)',
      primary25: '#F8FBFF',
      primary50: '#CAE3FF',
      primary100: '#9CCAFF',
      primary200: '#6FB1FF',
      primary300: '#4199FF',
      primary400: '#1380FF',
      primary500: '#0069E4',
      primary600: '#0054B6',
      primary700: '#00418D',
      primary800: '#002E64',
      primary900: '#001B3C',
    },
  },
}

export default config